<template>
    <div class="signin position-relative">
        <TitleHeader :title="title" :hide_profile="true" />

        <div v-if="step===1">
            <div class="sign_box pt-3">
                <h5>전화번호를 입력해주세요</h5>
                <p class="text-muted mb-5">안전을 위해 계정 확인이 필요해요</p>
            
                <div class="flex-start-center position-relative">
                    <span @click="lanuageList=!lanuageList" class="text-nowrap">
                        <span v-if="language =='ko'"><img src="@/assets/img/flag/094-south-korea.svg" alt="" width="25"><i class="fas ms-2 fa-caret-down"></i></span>
                        <span v-if="language =='en'"><img src="@/assets/img/flag/226-united-states.svg" alt="" width="25"><i class="fas ms-2 fa-caret-down"></i></span>
                        <span v-if="language =='cn'"><img src="@/assets/img/flag/034-china.svg" alt="" width="25"><i class="fas ms-2 fa-caret-down"></i></span>
                        <span v-if="language =='jp'"><img src="@/assets/img/flag/063-japan.svg" alt="" width="25"><i class="fas ms-2 fa-caret-down"></i></span>
                    </span>
                    <ul v-if="lanuageList" class="position-absolute top-100 select-ul border p-2 rounded bg-white top-100">
                        <li class="small" @click="SetLocale('ko')">
                            <img src="@/assets/img/flag/094-south-korea.svg" alt="" width="25" class="me-2">Korean</li>
                        <li class="small" @click="SetLocale('en')">
                            <img src="@/assets/img/flag/226-united-states.svg" alt="" width="25" class="me-2">English</li>
                        <li class="small" @click="SetLocale('cn')">
                            <img src="@/assets/img/flag/034-china.svg" alt="" width="25" class="me-2">Chinese</li>
                        <li class="small" @click="SetLocale('jp')">
                            <img src="@/assets/img/flag/063-japan.svg" alt="" width="25" class="me-2">Japanese</li>
                    </ul>
                    <input type="tel" class="form-control border-0 text-body fs-px-20" placeholder="010 0000 0000" v-model="cell_phone">
                </div>
            </div>
            <div class="sign_box vstack gap-2 position-absolute bottom-0 start-50 translate-middle-x w-100 py-3">
                <div @click="step=2" class="btn bg-main text-white py-px-12">인증번호 받기
                </div>
                <!-- <hr class="border-gray-500 my-1">
                <router-link to="/social-login" class="btn bg-white border-gray-300 py-px-12">다른 방법으로 회원가입
                </router-link> -->
            </div>
        </div>

        <div v-if="step===2">
            <div class="sign_box pt-3">
                <h5 class="fw-bold mb-2">{{cell_phone}}</h5>
                <h6 class="text-muted mb-5">전송된 인증번호를 입력해주세요 (000000)</h6>
                <div class="">
                    <label class="flex-center-center gap-2 flex-between-center mb-3 code_box position-relative" for="code_input">
                        <input type="text" class="certify_number" v-model="num_1" :class="{'filled':num_1!=''}" maxlength="1" readonly>
                        <input type="text" class="certify_number" v-model="num_2" :class="{'filled':num_2!=''}" maxlength="1" readonly>
                        <input type="text" class="certify_number" v-model="num_3" :class="{'filled':num_3!=''}" maxlength="1" readonly>
                        <input type="text" class="certify_number" v-model="num_4" :class="{'filled':num_4!=''}" maxlength="1" readonly>
                        <input type="text" class="certify_number" v-model="num_5" :class="{'filled':num_5!=''}" maxlength="1" readonly>
                        <input type="text" class="certify_number" v-model="num_6" :class="{'filled':num_6!=''}" maxlength="1" readonly>
    
                        <input type="text" id="code_input" class="position-absolute top-0 left-0 w-100 opacity-0" v-model="num_full">
                    </label>
                    <!-- <input
                        v-for="(input, index) in inputs"
                        :key="index"
                        type="number"
                        class="certify_number"
                        maxlength="1"
                        @input="handleInput(index)"
                        ref="inputFields"
                        :autofocus="index === 0"
                        > -->
                </div>
                <div class="text-center text-muted pt-3 fs-px-12">
                    <div class="text-danger" v-show="notCorrect">인증번호가 틀립니다.</div>
                    <div v-if="timeLeft > 0">
                        {{ minutes }}:{{ seconds }}
                    </div>
                    <div class="text-danger" v-else>
                        인증번호가 만료되었습니다.
                    </div>
                </div>
            </div>
            <div class="position-absolute bottom-0 text-center small w-100 py-3 text-muted">
                인증번호를 받지 못하셨나요? <span class="text-primary">재전송</span></div>
        </div>
        <div v-if="step===3">
            <div class="position-absolute top-50 start-50 translate-middle w-100 fs-px-18 text-center">
                <i class="fal fa-check-circle fa-4x mb-4 text-main"></i>
                <h4>인증되었습니다.</h4>
            </div>
            
            <div class="sign_box vstack gap-2 position-absolute bottom-0 start-50 translate-middle-x w-100 py-3">
                <router-link to="/signup" class="btn bg-main py-px-12 text-white mt-3">회원등록 계속하기
                </router-link>
                <div class="mt-3 text-center">
                    <router-link to="/">다음에 하기
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '회원가입',
            lanuageList:false,
            language: 'ko',
            step:1,
            cell_phone:null,


            num_full: null,
            num_1: '',
            num_2: '',
            num_3: '',
            num_4: '',
            num_5: '',
            num_6: '',
            number : '',


            email: '',
            password: '',
            timeLeft: 300, // 초 단위의 남은 시간
            timer: null, // 타이머 객체
            notCorrect:false,
        }
    },
    
    watch:{
        num_full(){
            if( this.num_full.length > 6 ){
                this.num_full = this.num_full.substr(0, 6); 
            }else{
                this.num_1 = this.num_full.substr(0, 1); 
                this.num_2 = this.num_full.substr(1, 1); 
                this.num_3 = this.num_full.substr(2, 1); 
                this.num_4 = this.num_full.substr(3, 1); 
                this.num_5 = this.num_full.substr(4, 1); 
                this.num_6 = this.num_full.substr(5, 1); 

                if( this.num_full.length == 6 ){
                    this.chk();
                }
            }
        }
    },
    computed: {
        minutes() {
        return Math.floor(this.timeLeft / 60);
        },
        seconds() {
            let sec = this.timeLeft % 60;
            if( (sec.toString()).length < 2 ){
                sec = '0'+(this.timeLeft % 60).toString();
            }
            return sec;
        }
    },
    methods:{
        // handleInput(index) {
        //     const input = this.$refs.inputFields[index];
        //     const maxLength = parseInt(input.getAttribute('maxlength'));
        //     const currentLength = input.value.length;

        //     if (currentLength >= maxLength) {
        //         if (index < this.inputs.length - 1) {
        //         this.$refs.inputFields[index + 1].focus();
        //         }
        //     } else if (currentLength === 0 && index > 0) {
        //         this.$refs.inputFields[index - 1].focus();
        //     }

        //     this.inputs[index] = input.value;
        //     if (this.inputs.every(input => input.length === 1)) {
        //         this.chk();
        //     }
        // },
        resetInputsAndFocus() {
            this.notCorrect = true;
            this.num_full = '';
            this.num_1 = ''; 
            this.num_2 = ''; 
            this.num_3 = ''; 
            this.num_4 = ''; 
            this.num_5 = ''; 
            this.num_6 = ''; 
        },
        SetLocale(lang){
            this.language = lang;
            this.lanuageList = false
        },
        chk(){
            // const collectedNumbers = this.inputs.join('');
            if(this.num_full === '000000'){
                this.step=3;
            }else{
                this.resetInputsAndFocus();
            }
        },
        Login(){
            // 이메일 틀렸을 경우
            this.showModal1 = true;

            // 이메일은 맞고 비번이 틀렸을 경우
            this.showModal2 = true;
        },
        SendPw(){
            this.showModal2 = false;
            this.showModal3 = true;
        },
        updateTimer() {
            if (this.timeLeft > 0) {
            this.timeLeft--;
            } else {
            clearInterval(this.timer); // 타이머 정지
            }
        }
    },
    
    mounted(){
        this.timer = setInterval(this.updateTimer, 1000);
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.step=1;
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
    .signin{
        height: 100vh;
        min-height: 500px;
    }
    .certify_number{
        background: #efefef;
        border-radius: 4px;
        width: 35px;
        padding: 5px;
        text-align: center;
        border: 0;
    }
    .certify_number.filled{
        background-color: #fff;
        border: 1px solid orange;
    }

</style>