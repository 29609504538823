<template>
    <div class="signup position-relative">
        <TitleHeader :title="title" :hide_profile="true" />

        <div class="wrap">
            <div class="position-relative">
                <div class="ratio ratio-4x3 intro_bg bg-img">
                </div>
                <div class="intro_logo position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center">
                    <img src="@/assets/img/logo/bunda_singer2_trim.svg" alt="번다싱어 로고">
                </div>
            </div>
            <div class="btn_box position-relative">
                <div class="sns_box vstack gap-2 w-100 p-3 rounded">
                    <router-link to="/" class="btn w-100 sns_btn sns_kakao d-flex py-2 align-items-center">
                        <img src="@/assets/img/sns/kakaotalk.png" width="26">
                        <span class="tit w-100 fw-bold lh-1">Kakao로 회원가입</span>
                    </router-link>
                    <router-link to="/" class="btn w-100 sns_btn sns_naver text-white d-flex py-2 align-items-center">
                        <img src="@/assets/img/sns/naver_white.png" width="26">
                        <span class="tit w-100 fw-bold lh-1">Naver로 회원가입</span>
                    </router-link>
                    <router-link to="/" class="btn w-100 sns_btn sns_apple text-white d-flex py-2 align-items-center border">
                        <img src="@/assets/img/sns/apple_white.png" width="26">
                        <span class="tit w-100 fw-bold lh-1">Apple로 회원가입</span>
                    </router-link>
                    <router-link to="/" class="btn w-100 sns_btn sns_facebook text-white d-flex py-2 align-items-center">
                        <img src="@/assets/img/sns/facebook_white.png" width="26">
                        <span class="tit w-100 fw-bold lh-1">Facebook으로 회원가입</span>
                    </router-link>
                    <hr>
                    <router-link to="/signup" class="btn w-100 border d-flex py-2 text-white">
                        <span class="tit w-100">이메일로 회원가입</span>
                    </router-link>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '회원가입'
        }
    },
    computed:{
    },
    methods:{
    },
    
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .wrap{
        display: flex;
        flex-grow: 1;
        min-height: calc(100vh - 60px);
        flex-direction: column;
    }
    .signup{
        min-height: 100vh;
    }

    .intro_bg{
        opacity: 0.22;
    }

    .intro_logo{
        width: 150px;
        height: 100px;
        border-radius: 20px;

        img{
            width: 120px;
            filter: drop-shadow(0px 4px 40px rgba(0,0,0,0.4));
        }
    }

    .certi_btn{
        line-height: 35px;
        min-width: 90px;
        font-size: 14px;
    }

    .round_checkbox label{
        overflow: hidden;
    }

    .btn_box{
        flex: 1;
    }

    .sns_box{    
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .intro_bg{
        background-image: url('../../assets/img/sign_intro.jpg');
    }
</style>