<template>
    <div class="signin position-relative">
        <TitleHeader :title="title" :hide_profile="true" />

        <div class="px-3">
            <div class="sns_box vstack gap-2 w-100 px-3">
                <router-link to="/" class="btn w-100 sns_btn sns_kakao d-flex py-2 align-items-center">
                    <img src="@/assets/img/sns/kakaotalk.png" width="26">
                    <span class="tit w-100 fw-bold lh-1">Kakao로 시작하기</span>
                </router-link>
                <router-link to="/" class="btn w-100 sns_btn sns_naver text-white d-flex py-2 align-items-center">
                    <img src="@/assets/img/sns/naver_white.png" width="26">
                    <span class="tit w-100 fw-bold lh-1">Naver로 시작하기</span>
                </router-link>
                <router-link to="/" class="btn w-100 sns_btn sns_apple text-white d-flex py-2 align-items-center border">
                    <img src="@/assets/img/sns/apple_white.png" width="26">
                    <span class="tit w-100 fw-bold lh-1">Apple로 시작하기</span>
                </router-link>
                <router-link to="/" class="btn w-100 sns_btn sns_facebook text-white d-flex py-2 align-items-center">
                    <img src="@/assets/img/sns/facebook_white.png" width="26">
                    <span class="tit w-100 fw-bold lh-1">Facebook으로 시작하기</span>
                </router-link>
            </div>
            <!-- <div class="sign_box vstack gap-2 position-absolute bottom-0 start-50 translate-middle w-100 px-3">
                <router-link to="/certify" class="btn w-100 bg-secondary text-white d-flex py-2 align-items-center">
                    <span class="tit w-100">다른 이메일로 로그인</span>
                </router-link>
                <router-link to="/certify" class="btn w-100 border d-flex py-2 text-body">
                    <span class="tit w-100">회원가입</span>
                </router-link>
            </div> -->
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '로그인',
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .signin{
        height: 100vh;
        min-height: 500px;
    }
</style>